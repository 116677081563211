import React from 'react';
import './JobDetails.css'; // Optional: For additional styling
import { useState } from 'react';

// Import the Document and Page components
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Box, Container } from '@mui/material';

// Import MU Material icons
import ListIcon from '@mui/icons-material/List';
import AttachFileIcon from '@mui/icons-material/AttachFile';

// Individual components
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';



// Set the worker source for pdfjs
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

function JobDetails({ jobItems, jobNotes }) {

  const [activeTab, setActiveTab] = useState('itemized');

  return (
    <Container fixed sx={{ backgroundColor: '#F2F2F7', paddingBottom: 5, mt: 2 }}>
      {/* Tabs */}
      <div className="job-tabs">

        <button
          className={`tab-item ${activeTab === 'itemized' ? 'active' : ''}`}
          onClick={() => setActiveTab('itemized')}>
          <ListIcon /> Itemized
        </button>

        <button
          className={`tab-item ${activeTab === 'floorplan' ? 'active' : ''}`}
          onClick={() => setActiveTab('floorplan')}>
          <AttachFileIcon /> Floorplan
        </button>
      </div>


      {activeTab === 'itemized' && (
      
      <div>
        <div>

          {/* If no items are available, show message below */}
          {jobItems?.length === 0 && (
            <div className="no-items-message">No items available</div>
          )}

          
          </div>

          {jobItems?.length > 0 && ( 

          <div className='bg-white' style={{padding: '10px'}}>

          {/* Job Items */}
          <Box className="job-items fake-data"
          sx={{
            overflowX: 'auto', 
            display: 'flex',   
            gap: 2,            
            scrollbarWidth: '2px',
          }}
          >
            {jobItems?.map((item, index) => (
              <Box key={index} className="job-item job-item-row"
              sx={{
                display: 'flex', 
                alignItems: 'center', 
                minWidth: 'max-content'
              }}
              >

                <TextField
                  id="outlined-helperText"
                  label="Type"
                  value={item.item_type}
                  disabled
                  sx={{ maxWidth: 100 }}
                />

                <TextField
                  id="outlined-helperText"
                  label="Quantity"
                  value={item.quantity}
                  disabled
                  sx={{ maxWidth: 90 }}
                />

                <TextField
                  id="outlined-helperText"
                  label="Unit"
                  value={item.unit}
                  disabled
                  sx={{ maxWidth: 90 }}
                />

                <TextField
                  id="outlined-helperText"
                  label="Description"
                  className='fake-data'
                  value={item.description}
                
                  disabled

                />

                <TextField
                  id="outlined-helperText"
                  label="Area"
                  className='fake-data'
                  value={item.area}
                  disabled
                  sx={{ maxWidth: 90 }}
                />
               
                <TextField
                  id="outlined-helperText"
                  label="Cost per Unit"
                  className='fake-data'
                  value={item.cost_per_unit}
                  disabled
                  sx={{ maxWidth: 120 }}
                />
                
                <TextField
                  id="outlined-helperText"
                  label="Total Cost"
                  value={item.total_cost}
                  className='fake-data'
                  disabled
                  sx={{ maxWidth: 120 }}
                />
              </Box>
            ))}
          </Box>

          {/* Job Notes */}
          {jobNotes?.length > 0 && (
          <div className="job-notes">

            
<Box
  sx={{
    position: 'relative',
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: 2,
    margin: '16px 0',
  }}
>
  {/* Label */}
  <Typography
    variant="caption"
    sx={{
      position: 'absolute',
      top: -10,
      left: 12,
      backgroundColor: '#f9f9f9',
      padding: '0 4px',
      color: '#666',
    }}
  >
    Notes for Worker
  </Typography>

  {/* Notes */}
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 1, // Spacing between notes
    }}
  >
    {jobNotes?.map((note, index) => (
      <Typography
        key={index}
        sx={{
          color: note.note_type === 'important' ? 'red' : 'black',
          whiteSpace: 'pre-wrap',
          fontFamily: 'Comic Sans MS',
        }}
        className='fake-data'
      >
        {note.note_text}
      </Typography>
    ))}
  </Box>
</Box>
          </div>
          )}

           {/* If no notes are available, show message below */}
           {jobNotes?.length === 0 && (
              <div className="no-notes-message">No notes available</div>
            )}

        </div>
        )}
        </div>
      )}


      {activeTab === 'floorplan' && (
        <div className="floorplan-section px-4">
          {/* Empty section for Floorplan 
          <div className="empty-section">No floorplan available</div>
          */}

             {/* Button to Open PDF */}
   

             <Button
         
         onClick={() => window.open('./Westfield_3x2_1218_SF_Waterford_Place_Apartments.pdf', '_blank')}
         sx={{ backgroundColor: '##2222226E', color: 'white', ml: 2, background: '#2222226E', textTransform: 'none',
            padding: '10px', borderRadius: '9px', position: 'relative', top: '60px', zIndex: '1000', fontSize: '16px' }}
       >
         Open in new Tab
       </Button>

     

          {/* PDF Viewer */}
          <Document file="./Westfield_3x2_1218_SF_Waterford_Place_Apartments.pdf">
            <Page pageNumber={1} width={830} />
            <Page pageNumber={2} width={830} />
            <Page pageNumber={3} width={830} />
          </Document>
        </div>
      )}

    </Container>
  );
}

export default JobDetails;




