import React, { useState, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';  // Assuming you're using this date picker library
import DataTable from 'react-data-table-component';


import './FullList.css';
import { Container, Stack } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';

// Material UI Icons
import TodayIcon from '@mui/icons-material/Today';
import WorkIcon from '@mui/icons-material/Work';
import RoomIcon from '@mui/icons-material/Room';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PaidIcon from '@mui/icons-material/Paid';
import NumbersIcon from '@mui/icons-material/Numbers';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ClearIcon from '@mui/icons-material/Clear';

// Individual components
import JobDetails from '../components/JobDetails';
import Chip from '@mui/material/Chip';
import PdfPreview from '../components/PDFPreview';
import FilterComponent from '../components/FilterComponent';

const FullList = () => {
  // State variables
  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState('');
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [activeCity, setActiveCity] = useState('Denver');
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedWorker, setSelectedWorker] = useState(selectedRow?.worker || '');
  const [tableData, setTableData] = useState([]);
  const [workers, setWorkers] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const detailViewRef = useRef(null);

  const cities = ['Denver', 'Las Vegas', 'Phoenix'];

  // Search component
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  // const filteredItems = fakeUsers.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())); // what is this supposed to be  used for?
  const filteredItems = tableData.filter(item => item.description && item.description.toLowerCase().includes(filterText.toLowerCase()));
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: <p style={{ color: '#111111B2' }}><WorkIcon /> Job Description</p>,
      selector: row => `${row.description}`,
      sortable: true,
      cell: row => (
        <span className="highlight-description fake-data">{row.description}</span>
      ),
      sortFunction: (a, b) => {
        // Function to sum quantities in job_items
        const getTotalQuantity = (job) => {
          return job.job_items.reduce((sum, item) => sum + item.quantity, 0);
        };
      
        // Calculate total quantities for each job
        const totalA = getTotalQuantity(a);
        const totalB = getTotalQuantity(b);
      
        // Compare totals for sorting
        return totalA - totalB;
      },
    },
    {
      name: <p style={{ color: '#111111B2' }}><RoomIcon /> Address</p>,
      selector: row => row.address,
      sortable: true,
      cell: row => (
        <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', width: '100%' }}>
          <span className='fake-data'>{row.address}</span>
          <span className="ms-2" style={{ background: '#E5E5EA', padding: '5px 7px', borderRadius: '10px' }}>
            <PdfPreview pdfUrl='./Westfield_3x2_1218_SF_Waterford_Place_Apartments.pdf' />
          </span>
        </div>
      ),
    },

    {
      name: <p style={{ color: '#111111B2' }}><svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.436 3.40972C1.19209e-07 3.90572 0 4.75772 0 6.45972V15.8537C0 17.0597 5.96046e-08 17.6627 0.315 18.1167C0.629 18.5707 1.179 18.7617 2.279 19.1427L3.573 19.5917C4.274 19.8347 4.813 20.0217 5.266 20.1417C5.562 20.2207 5.833 19.9887 5.833 19.6827V4.53272C5.82954 4.41127 5.78597 4.29439 5.70908 4.20032C5.6322 4.10625 5.52633 4.04028 5.408 4.01272C5.019 3.91372 4.551 3.75172 3.91 3.52972C2.357 2.99072 1.58 2.72172 0.99 2.99472C0.778826 3.09359 0.590251 3.23485 0.436 3.40972ZM10.62 1.74372L9.084 2.80872C8.529 3.19372 8.121 3.47672 7.774 3.67772C7.69257 3.72378 7.6245 3.79021 7.57646 3.8705C7.52843 3.95079 7.50208 4.04218 7.5 4.13572V19.1827C7.5 19.5527 7.884 19.7847 8.196 19.5847C8.531 19.3707 8.915 19.1047 9.38 18.7827L10.916 17.7177C11.471 17.3327 11.879 17.0497 12.226 16.8487C12.3074 16.8027 12.3755 16.7362 12.4235 16.6559C12.4716 16.5756 12.4979 16.4843 12.5 16.3907V1.34272C12.5 0.971716 12.116 0.740716 11.804 0.939716C11.469 1.15472 11.085 1.42072 10.62 1.74372ZM17.72 1.38272L16.427 0.934716C15.726 0.691716 15.187 0.504716 14.734 0.384716C14.438 0.305716 14.167 0.537716 14.167 0.843716V15.9937C14.1705 16.1152 14.214 16.232 14.2909 16.3261C14.3678 16.4202 14.4737 16.4862 14.592 16.5137C14.981 16.6127 15.449 16.7737 16.09 16.9967C17.643 17.5357 18.42 17.8047 19.01 17.5317C19.2212 17.4328 19.4097 17.2916 19.564 17.1167C20 16.6207 20 15.7687 20 14.0667V4.67272C20 3.46672 20 2.86272 19.685 2.40972C19.371 1.95572 18.821 1.76472 17.721 1.38372" fill="#111111" fillOpacity="0.7" />
      </svg>  Zip Code</p>,
      selector: row => row.zip_code,
      cell: row => (
        <span className='fake-data'>{row.zip_code}</span>
      ),
      sortable: true,
    },

    {
      name: <p style={{ color: '#111111B2' }}><LocalOfferIcon /> Place Name</p>,
      selector: row => row.place_name,
      cell: row => (
        <span className='fake-data'>{row.place_name}</span>
      ),
      sortable: true,
    },
    {
      name: <p style={{ color: '#111111B2' }}><CalendarMonthIcon /> Date Requested</p>,
      selector: row => row.date_requested,
      cell: row => (
        <span className='fake-data'>{row.date_requested}</span>
      ),
      sortable: true,
    },
    {
      name: <p style={{ color: '#111111B2' }}> <EngineeringIcon /> Worker</p>,
      selector: row => row.worker,  // : 'Unassigned', // TODO: get worker name here
      sortable: true,
      cell: row => (
        <div className='fake-data' style={{ display: 'flex', alignItems: 'center', textAlign: 'center', width: '100%' }}>
          <FormControl sx={{ flexGrow: 1 }}>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={row.worker?.id ? row.worker?.id : 'None'} // Default value
              className='fake-data'
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                backgroundColor: "#1D1B2014",
                width: "auto",
                minWidth: "80px", // set minimum width
                maxWidth: "300px", // set maximum width for longer names
                marginRight: "10px",
                height: "40px",
                textAlign: "left",
              }}
            >
              <MenuItem value={row.worker?.id ? row.worker?.id : 'None'}>{row.worker?.name ? row.worker?.name : 'None'}</MenuItem>
            </Select>
          </FormControl>
        </div>
      ),
      width: "auto", // flexible minimum width for the column    
    },
    {
      name: <p style={{ color: '#111111B2' }}><PaidIcon /> Payment</p>,
      selector: row => row.payment_status,
      sortable: true,
      cell: row => (
        <span className='fake-data'>
          {row.payment_status ? (
            <Chip className='fake-data' sx={{ background: "#4A44591F" }} icon={<CheckCircleIcon />} label='Paid' />
          ) : (
            <Chip  className='fake-data' sx={{ background: "#dadae1" }} icon={<CancelIcon sx={{ color: '#FFF', fontFamily: 'Comic Sans MS' }} color='info' />} label='Pending' />
          )}
        </span>
      ),
    },
    {
      name: 'Total Count',
      selector: row => row.counts.reduce((sum, count) => sum + count, 0),
      sortable: true,
      omit: true // Hide this column in the table, but it can be used for sorting
    }
  ];

  // Handle date change, this will be used to filter the data in the future
  const handleDateChange = date => {
    if (!date){
      setSelectedDate(null);
      setFormattedDate('Select Date');
      setOpenDatePicker(false); 

      
     return;
       
    }

  // Convert to YYYY-MM-DD format
    const ISODate = date.toISOString().split('T')[0]; 
    setSelectedDate(ISODate);
    setFormattedDate(date ? date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short' }) : '');
    setOpenDatePicker(false);
  };

  // Handle row click
  const handleRowClick = row => {
    setSelectedRow(row);
    setSelectedWorker(row.worker);
  };

  // Close detail view
  const closeDetailView = () => {
    setSelectedRow(null);
  };


  // Handle worker change and update the selected row in table data
  const handleWorkerChange = (e) => {
    let worker_id = e.target.value;
    let updatedWorker = workers.find(worker => worker.worker_id === parseInt(worker_id, 10));
    setSelectedWorker(worker_id); 

    // Update the worker in the table data
    const updatedTableData = [...tableData];
    const updatedRowIndex = updatedTableData.findIndex(row => row.jobOrder === selectedRow.jobOrder);

    if (updatedRowIndex > -1) {
      updatedTableData[updatedRowIndex] = {
        ...updatedTableData[updatedRowIndex],
        worker: updatedWorker, 
      };
      setTableData(updatedTableData); 
    }

    // TODO: Update the worker in the database with API call
  };

  // Function to detect clicks outside the modal
  const handleClickOutside = (event) => {
    if (detailViewRef.current && !detailViewRef.current.contains(event.target)) {
      closeDetailView();
    }
  };


// Fetch data from API
useEffect(() => {
  const fetchJobsAndWorkers = async (activeCity, selectedDate) => {
    setLoading(true); // Start loading
    try {
      // Build query parameters dynamically
      const params = new URLSearchParams();
      if (activeCity) params.append("warehouse", activeCity);
      if (selectedDate) params.append("date_requested", selectedDate);

      // Fetch job data
      let jobsResponse = await fetch(`http://127.0.0.1:8000/api/jobs?${params.toString()}`);
      if (!jobsResponse.ok) {
        throw new Error('Failed to fetch job data');
      }
      let jobsData = await jobsResponse.json();
      setTableData(jobsData);
      console.log("jobsData", jobsData);

      // Fetch workers data
      let workersResponse = await fetch(`http://127.0.0.1:8000/api/workers?${params.toString()}`);
      if (!workersResponse.ok) {
        throw new Error('Failed to fetch workers data');
      }
      let workersData = await workersResponse.json();
      setWorkers(workersData);

    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Check which parameters are available
  if (activeCity || selectedDate) {
    console.log("Re-running fetchJobsAndWorkers");
    fetchJobsAndWorkers(activeCity, selectedDate);
  }
}, [activeCity, selectedDate]); // Re-run when activeCity or selectedDate changes


  // useEffect to add/remove event listener
  useEffect(() => {
    if (selectedRow) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedRow]);


  // Display loading or error state
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (

    <Container sx={{ pl: 0 }} maxWidth={false} disableGutters   // Removes default maxWidth constraint
    >
      {/* Filter options */}
      <Container maxWidth={false}   // Removes default maxWidth constraint
      disableGutters sx={{ mb: 1, background: "#F2F2F7" }} className='ps-0'>

        <Stack direction="row" sx={{ py: 2, pl: 0, ml: '100px', background: "#F2F2F7" }}>

          {/* Date Picker Button */}
          <button
            className="btn btn-secondary me-3"
            onClick={() => setOpenDatePicker(!openDatePicker)}
            style={{ background: '#B1B1B1', border: 'none', borderRadius: '8px', color: '#fff' }}
          >
            <TodayIcon /> <strong>{formattedDate || 'Select Date'}</strong>
          </button>


          {openDatePicker && (
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              dateFormat="dd MMM"
              isClearable
              inline
              placeholderText="Select a date"
            />

          )}

          {/* Clear date button */}
          

          <Button 
        variant="contained" 
        sx={{ backgroundColor: '#111111B2', color: '#fff' }} 
        onClick={() => handleDateChange(null)}
        startIcon={<ClearIcon />}
      >
        Clear
      </Button>

          <div>
            {cities.map((city, index) => (
              <button
                key={index}
                className={`btn ${activeCity === city ? 'active-city' : ''} ms-5`}
                onClick={() => setActiveCity(city)}
              >
                {city}
              </button>
            ))}
          </div>
        </Stack>
      </Container>

      {/* Table */}
      <Container sx={{ mb: 5, background: 'white', py: 4, pl: '100px' }} maxWidth={false}  
      disableGutters
      >
        <DataTable
          columns={columns}
          data={filteredItems}
          onRowClicked={handleRowClick}
          subHeader 
          subHeaderComponent={subHeaderComponentMemo}
          highlightOnHover
          striped
          responsive
          customStyles={{
            rows: {
              style: {
                padding: '18px 0',
              }
            }
          }}
        />

        {/* Detail view */}
        {selectedRow && (
          <div className="detail-view" ref={detailViewRef}>
            <div className="d-flex justify-content-end mb-4">
              <button onClick={closeDetailView} className="btn btn-secondary btn-close" aria-label="Close"></button>
            </div>

            <div className="container">
              <div className="row align-items-center px-5">
                <div className="col firstDiv">
                  <div className="fw-bold mb-5">
                    <p><NumbersIcon sx={{ color: '#111111B2' }} /> Job Order</p>
                    <p className='ps-2 fake-data'>{selectedRow.job_order_number}</p>
                  </div>

                  <div className="fw-bold mb-5">
                    <p><CalendarMonthIcon sx={{ color: '#111111B2' }} /> Date Requested</p>
                    <p className='ps-2 fake-data'>{selectedRow.date_requested}</p>
                  </div>

                  <div className="fw-bold">
                    <p><WorkIcon sx={{ color: '#111111B2' }} /> Job Description</p>
                    <p className='ps-2 fake-data'>{selectedRow.description}</p>
                  </div>
                </div>

                <div className="col secondDiv">
                  <div className="fw-bold mb-5">
                    <div className='d-flex align-items-center'>
                      <EngineeringIcon sx={{ color: '#111111B2', marginRight: 1 }} />

                      <select
                        className="form-select ps-2 w-50 fake-data"
                        value={selectedWorker}
                        onChange={handleWorkerChange}
                        style={{
                          backgroundColor: '#1D1B2014', border: 'none', color: '#111111B2', height: '45px',
                          padding: '8px 10px', width: 'auto'
                        }}
                      >
                        <option value="" disabled>Select a worker</option>
                        {workers.map((worker, index) => (
                          <option key={index} value={worker.worker_id}>
                            {worker.name}
                          </option>
                        ))}
                      </select>


                    </div>
                  </div>

                  <div className="fw-bold mb-5">
                    <p><LocalOfferIcon sx={{ color: '#111111B2' }} />  Place Name</p>
                    <p className='ps-2'>{selectedRow.place_name}</p>
                  </div>

                  <div className="fw-bold">
                    <p><RoomIcon sx={{ color: '#111111B2' }} /> Address</p>
                    <p className='ps-2'>{selectedRow.address}</p>
                  </div>
                </div>
              </div>

              <div>
                <JobDetails jobItems={selectedRow.job_items || []} jobNotes={selectedRow.job_notes || []} />
              </div>
            </div>
          </div>
        )}
      
      </Container>
    </Container>
  );
};

export default FullList;
