import React, { useState } from "react";
import {
    Box,
    TextField,
    Button,
    Typography,
    Paper,
    Link,
    CircularProgress,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";

const LoginPage = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const theme = createTheme({
        palette: {
          customGray: {
            main: '#49454F',
          },
        },
      });

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");

        try {
            const response = await axios.post(
                "http://localhost:8000/token",
                new URLSearchParams({
                    grant_type: "",
                    username,
                    password,
                    scope: "",
                    client_id: "",
                    client_secret: "",
                }),
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        Accept: "application/json",
                    },
                }
            );
            console.log("Login successful:", response.data);
            // Redirect or handle login success
        } catch (err) {
            console.error("Login failed:", err);
            setError("Invalid username or password.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh",
                backgroundColor: "#f5f5f5",
            }}
        >
        
            <Paper
                elevation={3}
                sx={{
                    padding: 4,
                    borderRadius: 2,
                    backgroundColor: "#e9e9ee",
                    maxWidth: 700,
                    width: "100%",
                }}
            >
                <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold' }}>
                    Log in
                </Typography>
                <form onSubmit={handleLogin} style={{padding: '0 50px'}}>
                <ThemeProvider theme={theme}>

                    <TextField
                        label="Username"
                        fullWidth
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        autoComplete="username"
                        placeholder="Type here"
                        variant="filled"
                        color="customGray"
                        sx={{
                          '& .MuiFilledInput-root': {
                            backgroundColor: 'white',
                            '&:hover': { backgroundColor: 'white' },
                            '&.Mui-focused': { backgroundColor: 'white' },
                          },
                          mt: 5
                        }}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="current-password"
                        placeholder="Type here"
                        variant="filled"
                        color="customGray"
                       
                        sx={{
                          '& .MuiFilledInput-root': {
                            backgroundColor: 'white',
                            '&:hover': { backgroundColor: 'white' },
                            '&.Mui-focused': { backgroundColor: 'white' },
                          },
                          mt: 5
                        }}
                    />
                    {error && (
                        <Typography color="error" sx={{ mt: 1 }}>
                            {error}
                        </Typography>
                    )}
                    <Box
                        sx={{
                            textAlign: "right",
                        }}
                    >
                        <Link href="#" underline="hover" sx={{ fontSize: 14, color: '#49454F' }}>
                            Forgot Password?
                        </Link>
                    
                    </Box>

                    <Box sx={{textAlign: 'center'}}>
                    <Button
                            type="submit"
                            variant="contained"
                            sx={{
                                borderRadius: 50,
                                minWidth: 80,
                                height: 40,
                                px: 4,
                                mt: 3,
                                background: '#fff',
                                color: '#000',
                                textTransform: 'capitalize',
                                fontWeight: 'bold',
                               
                            }}
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={24} /> : "Enter"}
                        </Button>
                    </Box>
                    </ThemeProvider>
                </form>
            </Paper>
        </Box>
    );
};

export default LoginPage;
