import { Tooltip } from '@mui/material';
import { Card, CardContent, Typography } from '@mui/material';

const NotePreview = ({ note = { title: 'Sample title', content: 'Sample content' } }) => (
    <Tooltip
        title={
            <Card sx={{ minWidth: 275 }}>
                <CardContent>
                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14, fontWeight: 'bold' }}>
                        Notes
                    </Typography>

                    <Typography sx={{ mb: 1.5 }}>{note.title}</Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {note.content}
                    </Typography>

                </CardContent>

            </Card>
        }
        arrow
        placement="right"
    >
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5 6.2627H12.5M5.5 9.2627H9M16 1.2627H2C1.73478 1.2627 1.48043 1.36805 1.29289 1.55559C1.10536 1.74312 1 1.99748 1 2.2627V17.2627C1 17.5279 1.10536 17.7823 1.29289 17.9698C1.48043 18.1573 1.73478 18.2627 2 18.2627H16C16.2652 18.2627 16.5196 18.1573 16.7071 17.9698C16.8946 17.7823 17 17.5279 17 17.2627V2.2627C17 1.99748 16.8946 1.74312 16.7071 1.55559C16.5196 1.36805 16.2652 1.2627 16 1.2627Z" stroke="#111111" strokeOpacity="0.7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    </Tooltip>
);

export default NotePreview;