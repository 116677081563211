import { Document, Page } from 'react-pdf';
import { Tooltip } from '@mui/material';

const PdfPreview = ({ pdfUrl }) => (
  <Tooltip
    title={
      <Document file={pdfUrl}>
        <Page pageNumber={1} width={200} />
      </Document>
    }
    arrow
    placement="right"
  >
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 21.7627H5C4.46957 21.7627 3.96086 21.552 3.58579 21.1769C3.21071 20.8018 3 20.2931 3 19.7627V5.7627C3 5.23226 3.21071 4.72355 3.58579 4.34848C3.96086 3.97341 4.46957 3.7627 5 3.7627H19C19.5304 3.7627 20.0391 3.97341 20.4142 4.34848C20.7893 4.72355 21 5.23226 21 5.7627V19.7627C21 20.2931 20.7893 20.8018 20.4142 21.1769C20.0391 21.552 19.5304 21.7627 19 21.7627H14M9 3.7627V10.7627M21 10.7627H14M3 15.7627H12" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  </Tooltip>
);

export default PdfPreview;