import React from 'react';
import { TextField, Button, Box } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const FilterComponent = ({ onFilter, onClear, filterText }) => {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <TextField
        variant="outlined"
        size="small"
        placeholder="Search..."
        value={filterText}
        onChange={onFilter}
        sx={{ minWidth: 250 }} 
      />
      <Button 
        variant="contained" 
        sx={{ backgroundColor: '#111111B2', color: '#fff' }} 
        onClick={onClear}
        startIcon={<ClearIcon />}
      >
        Clear
      </Button>
    </Box>
  );
};

export default FilterComponent;
